module.exports = {
    'title': 'プロフィールの編集',
    'label_salutation': 'タイトル',
    'label_firstname': 'ファーストネーム（名）',
    'label_lastname': 'ラストネーム（姓）',
    'label_email': 'Eメールアドレス',
    'label_gender': '性別',
    'gender_type_male': '男性',
    'gender_type_female': '女性',
    'label_birthdate': '誕生日',
    'label_day': '日',
    'label_month': '月',
    'label_year': '年',
    'label_mobile_phone': '携帯電話番号',
    'label_slectbox': '選択する',
    'label_nationality': '国籍',
    'label_country_of_residence': '居住国',
    'label_language': '言語',
    'label_address': '住所',
    'terms_description': '新しいサービスやプロモーションの情報を受け取ることを希望します。',
    'monthly_sub_description': '月間アカウント概要の配信を希望する',
    'label_btn_submit': '変更を保存する'
}